// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-404-tsx": () => import("./../../../src/templates/404/404.tsx" /* webpackChunkName: "component---src-templates-404-404-tsx" */),
  "component---src-templates-calendar-index-tsx": () => import("./../../../src/templates/calendar/index.tsx" /* webpackChunkName: "component---src-templates-calendar-index-tsx" */),
  "component---src-templates-form-completed-tsx": () => import("./../../../src/templates/form/completed.tsx" /* webpackChunkName: "component---src-templates-form-completed-tsx" */),
  "component---src-templates-form-form-tsx": () => import("./../../../src/templates/form/form.tsx" /* webpackChunkName: "component---src-templates-form-form-tsx" */),
  "component---src-templates-games-game-tsx": () => import("./../../../src/templates/games/game.tsx" /* webpackChunkName: "component---src-templates-games-game-tsx" */),
  "component---src-templates-guideline-guideline-tsx": () => import("./../../../src/templates/guideline/guideline.tsx" /* webpackChunkName: "component---src-templates-guideline-guideline-tsx" */),
  "component---src-templates-index-index-tsx": () => import("./../../../src/templates/index/index.tsx" /* webpackChunkName: "component---src-templates-index-index-tsx" */)
}

